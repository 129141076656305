<template>
    <div>
        <div v-if="allSkills" class="inline-block">
            <div
                v-for="skill in allSkills"
                :key="skill.name"
                :class="[
                    selectedSkills.indexOf(skill.id) !== -1
                        ? activeClass
                        : defaultClass,
                ]"
                class="py-1.5 mr-2 mt-2 rounded-lg px-4 cursor-pointer inline-block text-xs"
                @click.prevent="onSelectSkill(skill.id)"
            >
                {{ skill.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
// lodash 排序方法
import { sortBy } from "lodash/collection";
export default {
    props: {
        // 選中時顏色
        activeClass: {
            type: String,
            default: null,
        },
        // 預設時顏色
        defaultClass: {
            type: String,
            default: null,
        },
        // 以選取項目
        selectedSkills: {
            type: Array,
            default() {
                return [];
            },
        },
        // 專業諮詢技能項目
        allSkills: {
            type: Array,
            required: true,
        },
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 選擇經驗分享與生活服務項目事件
         * @param { type Object(物件) } val 選中的服務id
         */
        onSelectSkill(id) {
            // 判斷選中服務技能值 是否存在 目前選中陣列中
            if (this.selectedSkills.indexOf(parseInt(id)) !== -1) {
                // 取得目前選中活動 比對目前服務技能值陣列中 index 位子
                const arrIndex = this.selectedSkills.findIndex(
                    (item) => item == id
                );
                // 刪除目前 index 位子值 作為取消選取
                this.selectedSkills.splice(arrIndex, 1);
            } else {
                // 將選中值 塞進服務技能陣列中
                this.selectedSkills.push(parseInt(id));
            }
            this.$emit("onSelectSkills", this.selectedSkills);
        },
    },
};
</script>
