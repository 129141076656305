<template>
    <div>
        <h5 class="mt-10 mb-5 text-lg md:text-2xl">
            編輯照片
            <span class="text-sm text-gray-400"
                >(選擇照片勾選時 代表第五分類不會出現)</span
            >
        </h5>
        <UploadImage
            ref="uploadImage"
            @onAboutFormValidate="validateCallBack"
            @onHiddenByCategory5Images="setImageDisabledByCategory5"
        />
        <slot name="video-upload"></slot>
        <div>
            <h5 class="mt-10 mb-5 text-lg md:text-2xl">編輯影片</h5>
            <UploadVideo
                ref="uploadVideo"
                @onHiddenByCategory5Videos="setVideoDisabledByCategory5"
            />
        </div>
        <h5 class="mt-10 mb-5 text-lg md:text-2xl md:mb-10">進階敘述</h5>
        <Form
            ref="aboutForm"
            :isLoading="isLoading"
            :isAdd="isAdd"
            :chooseActivities="chooseActivities"
            :chooseSkills="chooseSkills"
            @onAboutFormSubmit="callBack"
            @onAboutFormValidate="validateCallBack"
        />
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
// 檔案上傳
import UploadImage from "./UploadImage.vue";
// 影片上傳
import UploadVideo from "./UploadVideo.vue";
import Form from "./Form.vue";
export default {
    components: {
        UploadImage,
        UploadVideo,
        Form,
    },
    props: {
        // 更新動畫
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為新增
        isAdd: {
            type: Boolean,
            default: false,
        },
        // 可選擇可參與活動選項
        chooseActivities: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: "美食探索",
                        enabled: 1,
                    },
                ];
            },
        },
        // 可選擇經驗諮詢項目選項
        chooseSkills: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: "造型形象",
                        enabled: 1,
                    },
                ];
            },
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        onSubmit(form) {
            this.$refs.aboutForm.onSubmit(form);
            this.$refs.uploadImage.onSubmit();
            this.$refs.uploadVideo.onSubmit();
        },
        /**
         * 表單驗證成功後回傳值
         * @param { type Object(物件) } form 表單資了
         */
        callBack(form) {
            // about 資料表單驗證成功時的回傳表單資料
            this.$emit("update:aboutFormData", form);
            // 因為 更新時的 非同步 延遲問題 因此改用 function 作法 等待做完後 才執行更新 about 表單資料
            this.$emit("aboutFormDataCallback", form);
        },
        /**
         * 表單驗證失敗返回
         * @param { type Boolean(布林值) } validate 表單驗證結果
         */
        validateCallBack(validate) {
            // about資料 表單驗證結果回傳
            this.$emit("update:aboutFormValidate", validate);
        },
        // 設定表單資料
        setDefault(val) {
            this.$refs.aboutForm.setDefault(val);
            this.$refs.uploadImage.setDefault(val);
        },
        /**
         * 設定第五分類隱藏照片
         * @param { type Array(陣列) } ids
         */
        async setImageDisabledByCategory5(ids) {
            try {
                await this.$api.SetImageDisabledByCategory5Api(
                    this.$route.params.id,
                    ids
                );
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "隱藏照片不顯示於第五分類失敗",
                });
            }
        },
        /**
         * 設定第五分類隱藏影片
         * @param { type Array(陣列) } ids
         */
        async setVideoDisabledByCategory5(ids) {
            try {
                await this.$api.SetVideoDisabledByCategory5Api(
                    this.$route.params.id,
                    ids
                );
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "隱藏照片不顯示於第五分類失敗",
                });
            }
        },
    },
    // watch: {
    //     providerData(val) {
    //         this.setDefault(val);
    //     }
    // }
};
</script>
