<template>
    <div>
        <div>
            <div
                v-for="(item, index) in settedCustomSkills"
                :key="index"
                :class="defaultClass"
                class="py-1.5 mr-2 mt-2 rounded-lg px-4 cursor-pointer text-xs inline-block"
            >
                <span>{{ item }}</span>
                <span class="ml-2" @click.prevent="deleteCustom(index)">X</span>
            </div>
        </div>
        <button
            v-if="settedCustomSkills.length < 5"
            class="px-2 py-1 mx-1 my-2 mt-5 text-xs border border-black rounded-lg"
            @click.prevent="openDialog"
        >
            <i class="el-icon-plus"></i>自訂
        </button>
        <el-dialog :visible.sync="showDialog" width="100%" :show-close="false">
            <div class="bg-white rounded-lg max-w-[250px] p-5">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    @submit.native.prevent="onSubmit('form')"
                >
                    <el-form-item prop="skill">
                        <el-input
                            v-model="form.skill"
                            :max="4"
                            class="input"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="flex justify-center mt-5">
                    <button
                        class="mr-2 rounded border border-black px-3 py-0.5 text-sm text-black"
                        @click.prevent="closeDialog"
                    >
                        取消
                    </button>
                    <button
                        class="rounded bg-orange-600 px-3 py-0.5 text-sm text-white"
                        @click.prevent="onSubmit('form')"
                    >
                        確定
                    </button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        // 選中時顏色
        activeClass: {
            type: String,
            default: null,
        },
        // 預設時顏色
        defaultClass: {
            type: String,
            default: null,
        },
        // 以設定的自訂經驗交流及生活服務
        settedCustomSkills: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    computed: {
        skills() {
            return this.skills;
        },
    },
    data() {
        return {
            // 顯示彈窗
            showDialog: false,
            form: {},
            rules: {
                skill: [
                    {
                        required: true,
                        message: "請輸入自訂經驗諮詢項目名稱",
                        trigger: "blur",
                    },
                    { max: 4, message: "名稱最多四個字", trigger: "blur" },
                ],
            },
            // 自訂經驗交流及生活服務
            customSkills: [],
        };
    },
    methods: {
        // 開啟彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉彈窗
        closeDialog() {
            this.showDialog = false;
            // 移除表單驗證結果
            this.$refs.form.clearValidate("skill");
        },
        /**
         * 新增自訂
         * @param { type String(字串) } skill 技能名稱
         */
        addCustom(skill) {
            this.settedCustomSkills.push(skill);
            // 將新增完資料回傳給 母組件
            this.$emit("onChangeCustomSkills", this.settedCustomSkills);
            // 關閉彈窗
            this.closeDialog();
            // 清空資料
            this.form = {};
            // 移除表單驗證結果
            this.$refs.form.clearValidate("skill");
        },
        /**
         * 移除自訂
         * @param { type Number(數字) } index 陣列索引
         */
        deleteCustom(index) {
            // 刪除選擇活凳
            this.settedCustomSkills.splice(index, 1);
            // 將刪除完資料回傳給 母組件
            this.$emit("onChangeCustomSkills", this.settedCustomSkills);
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.addCustom(this.form.skill);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "資訊未填寫正確",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-dialog {
        @apply flex justify-center;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
    }
    .el-dialog__header {
        padding: 0;
    }
}

.input {
    @apply border border-gray-300 rounded;
}
</style>
