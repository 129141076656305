<template>
    <div>
        <div>
            <div
                v-for="(item, index) in settedCustomActivities"
                :key="index"
                :class="defaultClass"
                class="py-1.5 mx-1 mt-2 rounded px-4 cursor-pointer text-xs inline-block">
                <span>{{ item }}</span>
                <span class="ml-2" @click.prevent="deleteCustomActivity(index)">X</span>
            </div>
        </div>
        <button
            v-if="settedCustomActivities.length < 5"
            class="border border-black px-2 py-1 mt-5 rounded-lg my-2 text-xs"
            @click.prevent="openDialog">
            <i class="el-icon-plus"></i>自訂活動
        </button>
        <el-dialog :visible.sync="showDialog" width="100%" :show-close="false">
            <div class="bg-white rounded-lg max-w-[250px] p-5">
                <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent="onSubmit('form')">
                    <el-form-item prop="activity">
                        <el-input v-model="form.activity" :max="4"></el-input>
                    </el-form-item>
                </el-form>
                <div class="flex justify-center mt-5">
                    <button class="mr-2 rounded border border-black px-3 py-0.5 text-sm text-black" @click.prevent="closeDialog">取消</button>
                    <button class="rounded bg-red-500 px-3 py-0.5 text-sm text-white" @click.prevent="onSubmit('form')">確認</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        // 選中時顏色
        activeClass: {
            type: String,
            default: null,
        },
        // 預設時顏色
        defaultClass: {
            type: String,
            default: null,
        },
        // 以設定的自訂活動
        settedCustomActivities: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    computed: {
        activities() {
            return this.customActivities;
        },
    },
    data() {
        return {
            // 顯示彈窗
            showDialog: false,
            form: {},
            rules: {
                activity: [
                    { required: true, message: "請輸入自訂活動名稱", trigger: "blur" },
                    { max: 4, message: "活動名稱最多四個字", trigger: "blur" },
                ],
            },
            // 自訂活動
            customActivities: [],
        };
    },
    methods: {
        // 開啟彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉彈窗
        closeDialog() {
            this.showDialog = false;
            // 移除表單驗證結果
            this.$refs.form.clearValidate("activity");
        },
        /**
         * 新增自訂活動
         * @param { type String(字串) } activity 活動名稱
         */
        addCustomActivity(activity) {
            this.settedCustomActivities.push(activity);
            // 將新增完資料回傳給 母組件
            this.$emit("onChangeCustomActivities", this.settedCustomActivities);
            // 關閉彈窗
            this.closeDialog();
            // 清空資料
            this.form = {};
            // 移除表單驗證結果
            this.$refs.form.clearValidate("activity");
        },
        /**
         * 移除自訂活動
         * @param { type Number(數字) } index 陣列索引
         */
        deleteCustomActivity(index) {
            // 刪除選擇活凳
            this.settedCustomActivities.splice(index, 1);
            // 將刪除完資料回傳給 母組件
            this.$emit("onChangeCustomActivities", this.settedCustomActivities);
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.addCustomActivity(this.form.activity);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .el-dialog {
        @apply flex justify-center;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
    }
    .el-dialog__header {
        padding: 0;
    }
}
</style>
