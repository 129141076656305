<template>
    <el-form ref="form" class="relative" :model="form" :rules="rules">
        <LoadingComponent :isLoading="isLoading" />
        <div class="grid grid-cols-2 gap-4">
            <el-form-item class="w-full" prop="service_area">
                <label
                    >服務地區
                    <el-select
                        v-model="form.service_area"
                        class="w-full"
                        placeholder="請選擇服務地區"
                    >
                        <el-option
                            v-for="(item, key) in areaOptions"
                            :key="key"
                            :label="item.name"
                            :value="key"
                        >
                        </el-option>
                    </el-select>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="age">
                <label
                    >年齡
                    <div class="relative">
                        <el-input
                            v-model.number="form.age"
                            :maxlength="2"
                            class="w-full"
                        ></el-input>
                        <span
                            class="absolute top-0 font-light text-gray-400 right-2"
                            >歲</span
                        >
                    </div>
                </label>
            </el-form-item>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <el-form-item class="w-full" prop="height">
                <label
                    >身高
                    <div class="relative">
                        <el-input
                            v-model="form.height"
                            class="w-full"
                            :maxlength="3"
                        ></el-input>
                        <span
                            class="absolute top-0 font-light text-gray-400 right-2"
                            >公分</span
                        >
                    </div>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="weight">
                <label
                    >體重
                    <div class="relative">
                        <el-input
                            v-model="form.weight"
                            class="w-full"
                            :maxlength="3"
                        ></el-input>
                        <span
                            class="absolute top-0 font-light text-gray-400 right-2"
                            >公斤</span
                        >
                    </div>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="occupationInput">
                <label
                    >職業
                    <el-select
                        v-model="form.occupationInput"
                        class="w-full"
                        placeholder="請選擇職業"
                    >
                        <el-option
                            v-for="item in occupationOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </label>
            </el-form-item>
            <el-form-item
                v-if="form.occupationInput === 'JOB-OTHERS'"
                class="w-full"
                prop="occupationInputOther"
            >
                <label>
                    請輸入您的職業
                    <div class="relative">
                        <el-input
                            v-model="form.occupationInputOther"
                            maxlength="10"
                            class="w-full"
                        ></el-input>
                    </div>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="instagram">
                <label>
                    instagram
                    <div class="relative">
                        <el-input
                            v-model="form.instagram"
                            class="w-full"
                        ></el-input>
                    </div>
                </label>
            </el-form-item>
        </div>
        <el-form-item prop="skills">
            <div class="my-5 text-sm">
                <label class="text-gray-400">經驗諮詢項目</label>
                <PerfessionalConsultationSet
                    v-if="form.allSkills"
                    :selectedSkills="form.skills"
                    :allSkills="form.allSkills"
                    defaultClass="border border-gray-300 text-gray-300"
                    activeClass="bg-gradient-to-l from-red-500 to-pink-500 text-white"
                    @onSelectSkills="selectSkills"
                />
            </div>
        </el-form-item>
        <el-form-item>
            <CustomPerfessionalConsultationSet
                :settedCustomSkills="form.custom_skills"
                class="mt-5"
                defaultClass="bg-gradient-to-l from-red-500 to-pink-500 text-white"
                @onChangeCustomActivities="changeCustomSkills"
            />
        </el-form-item>
        <el-form-item prop="activities">
            <label class="font-light text-gray-400">可參與活動</label>
            <CanJoinActivity
                v-if="form.allActivities !== undefined"
                :selectedActivities="form.activities"
                :allActivities="form.allActivities"
                defaultClass="border border-gray-300 text-gray-300 mx-1"
                activeClass="bg-gradient-to-l from-red-500 to-pink-500 text-white mx-1"
                @onSelectActivities="selectActivities"
            />
        </el-form-item>
        <el-form-item prop="custom_activities">
            <label class="font-light text-gray-400">自訂活動</label>
            <CustomJoinActivity
                :settedCustomActivities="form.custom_activities"
                defaultClass="bg-gradient-to-l from-red-500 to-pink-500 text-white mx-1"
                @onChangeCustomActivities="changeCustomActivities"
            />
        </el-form-item>
        <el-form-item prop="taboo">
            <ul>
                <li
                    v-for="(item, index) in rulesText"
                    :key="index"
                    :class="[
                        rulesText.length === index + 1 && isMobile
                            ? 'mb-2'
                            : '',
                        isMobile ? '' : 'ml-2',
                    ]"
                    class="flex-shrink px-2 py-1 mt-2 text-sm text-orange-600 border border-red-500 rounded-lg cursor-pointer md:inline-block"
                    @click="addRuleBtn(item)"
                >
                    {{ item }}
                </li>
            </ul>
            <label class="font-light text-gray-400">服務商守則</label>
            <el-input
                v-model="form.taboo"
                type="textarea"
                rows="10"
                :maxlength="descriptionMaxLengthLimit"
                placeholder="請填寫會員遵守的項目"
            ></el-input>
            <div class="text-right">
                <span
                    :class="
                        form.taboo.length > descriptionMaxLengthLimit
                            ? 'text-orange-600'
                            : ''
                    "
                    >{{ form.taboo.length }}/{{
                        descriptionMaxLengthLimit
                    }}</span
                >
            </div>
        </el-form-item>
        <el-form-item prop="description">
            <label class="font-light text-gray-400">文字介紹</label>
            <el-input
                v-model="form.description"
                type="textarea"
                rows="10"
                :maxlength="descriptionMaxLengthLimit"
                placeholder="寫下更多關於您的介紹，讓大家更認識您！（可以使用 # 強調內容喔）"
            ></el-input>
            <div class="text-right">
                <span
                    :class="
                        form.description.length > descriptionMaxLengthLimit
                            ? 'text-orange-600'
                            : ''
                    "
                    >{{ form.description.length }}/{{
                        descriptionMaxLengthLimit
                    }}</span
                >
            </div>
        </el-form-item>
        <el-form-item prop="welcome_message">
            <div class="flex">
                <label class="flex-1 font-light text-gray-400">歡迎訊息</label>
                <el-switch
                    v-model="form.welcome_message_enabled"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                >
                </el-switch>
            </div>
            <el-input
                v-model="form.welcome_message"
                type="textarea"
                rows="10"
                :maxlength="descriptionMaxLengthLimit"
            ></el-input>
            <div v-if="form.welcome_message" class="text-right">
                <span
                    :class="
                        form.welcome_message.length > descriptionMaxLengthLimit
                            ? 'text-orange-600'
                            : ''
                    "
                    >{{ form.welcome_message.length }}/{{
                        descriptionMaxLengthLimit
                    }}</span
                >
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// 使用語系檔取得地區資料
import { areas } from "../../../../../../langs/tw.json";
// loading 動畫組件
import LoadingComponent from "../../../../../../components/Loading.vue";
/**
 * formCheckAgeRange: 判斷年紀不可小於 20 且不大於 99
 * formNumberRange: 判斷輸入值不可大於 999
 */
import {
    formCheckAgeRange,
    formNumberRange,
} from "../../../../../../service/formRules";
import { firebaseConnectRef } from "@/plugins/firebase";
// 可參與活動
import CanJoinActivity from "./CanJoinActivity.vue";
// 自訂活動
import CustomJoinActivity from "./CustomJoinActivity.vue";
// 經驗交流及生活服務
import PerfessionalConsultationSet from "./PerfessionalConsultationSet.vue";
// 自訂經驗交流及生活服務
import CustomPerfessionalConsultationSet from "./CustomPerfessionalConsultationSet.vue";

// lodash 排序方法
import { sortBy } from "lodash/collection";

export default {
    components: {
        LoadingComponent,
        CanJoinActivity,
        CustomJoinActivity,
        PerfessionalConsultationSet,
        CustomPerfessionalConsultationSet,
    },
    props: {
        // 更新動畫
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為新增
        isAdd: {
            type: Boolean,
            default: false,
        },
        // 可選擇可參與活動選項
        chooseActivities: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: "美食探索",
                        enabled: 1,
                    },
                ];
            },
        },
        // 可選擇經驗諮詢項目選項
        chooseSkills: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: "造型形象",
                        enabled: 1,
                    },
                ];
            },
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            form: {
                description: "",
                taboo: "",
                occupationInput: "",
                occupationInputOther: "",
                welcome_message: "",
                welcome_message_enabled: 1,
                allActivities: [],
                activities: [],
                custom_activities: [],
                allSKills: [],
                skills: [],
                custom_skills: [],
            },
            rules: {
                service_area: [
                    {
                        required: true,
                        message: "服務地區為必填",
                        trigger: "change",
                    },
                ],
                occupationInput: [
                    {
                        required: false,
                        message: "職位為必填",
                        trigger: "change",
                    },
                ],
                occupationInputOther: [
                    {
                        required: true,
                        message: "其他職位為必填",
                        trigger: "change",
                    },
                ],
                age: [
                    {
                        required: true,
                        message: "年齡為必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `年齡必須為整數`,
                        trigger: "blur",
                    },
                    { validator: this.formCheckAgeRange, trigger: "blur" },
                ],
                height: [
                    {
                        required: true,
                        message: "身高為必填",
                        trigger: "blur",
                    },
                    { validator: this.formNumberRange, trigger: "blur" },
                    {
                        pattern: /^[0-9]*$/,
                        message: `身高必須為整數`,
                        trigger: "blur",
                    },
                ],
                weight: [
                    {
                        required: true,
                        message: "體重為必填",
                        trigger: "blur",
                    },
                    { validator: this.formNumberRange, trigger: "blur" },
                    {
                        pattern: /^[0-9]*$/,
                        message: `體重必須為整數`,
                        trigger: "blur",
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "文字介紹為必填",
                        trigger: "blur",
                    },
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `介紹文字最多${process.env.VUE_APP_INPUT_MAX_LIMIT}個字`,
                        trigger: "blur",
                    },
                ],
                taboo: [
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `服務商守則最多${process.env.VUE_APP_INPUT_MAX_LIMIT}個字`,
                        trigger: "blur",
                    },
                ],
                welcome_message: [
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `歡迎訊息最多${process.env.VUE_APP_INPUT_MAX_LIMIT}個字`,
                        trigger: "blur",
                    },
                ],
                activities: [
                    {
                        required: true,
                        message: "請選擇可參與活動",
                        trigger: "blur",
                    },
                    { validator: this.formActivities, trigger: "blur" },
                ],
            },
            // 區域選擇,
            areaOptions: areas,
            // 文字介少最長字數限制
            descriptionMaxLengthLimit: process.env.VUE_APP_INPUT_MAX_LIMIT,
            // 服務守則快速新增範例按鈕
            rulesText: [
                "預訂時請於訂單說明您計畫讓我參與的活動為何？",
                "無法承接以下場合的快閃活動預訂：",
                "僅承接以下城市的預訂:",
                "彼此互相尊重，成熟的行為舉止",
            ],
            occupationOptions: [
                //  { "id": "JOB-NULL", "name": "不顯示" },
            ],
            // 選擇的活動
            selectedActivies: [],
            // 選擇的經驗交流與生活服務
            selectedSkills: [],
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        // 有更新參與活動時觸發
        selectActivities(activities) {
            this.selectedActivies = activities;
            this.$set(this.form, "activities", activities);
        },
        // 有更新自訂活動時觸發
        changeCustomActivities(customActivities) {
            this.$set(this.form, "custom_activities", customActivities);
        },
        // 有更新經驗交流及生活服務
        selectSkills(skills) {
            this.selectedSkills = skills;
            this.$set(this.form, "skills", skills);
        },
        // 有更新自訂經驗交流及生活服務時觸發
        changeCustomSkills(customSkills) {
            this.$set(this.form, "custom_skills", customSkills);
        },
        /**
         * 判斷是否選擇可參與活動
         */
        async formActivities(rule, value, callback) {
            // 判斷是否有選擇 可參與活動 以及是否選擇最少三項以上
            if (this.$isEmpty(value) || value.length < 3) {
                this.$message({
                    type: "error",
                    message: "最少設定三項可參與活動",
                });
                callback(new Error("最少設定三項可參與活動"));
            }
            callback();
        },
        /**
         * 判斷輸入值不可大於 999
         * @param { type String or Number(字串或數字) } value 輸入值
         */
        async formNumberRange(rule, value, callback) {
            // 判斷是身高還是體重的輸入匡錯誤
            const messageType = rule.field === "height" ? "身高" : "體重";
            const { success, message } = await formNumberRange(value);
            if (success) {
                return callback();
            }
            return callback(new Error(messageType + message));
        },
        /**
         * 判斷年齡不可大於 99且 不得小於 20
         * @param { type String or Number(字串或數字) } value 輸入值
         */
        async formCheckAgeRange(rule, value, callback) {
            const { success, message } = await formCheckAgeRange(value, true);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 職位欄位
                const occupation = [{ id: this.form.occupationInput }];

                const form = { ...this.form, occupation };
                if (this.isAdd) {
                    // about 表單資料回傳給父組件
                    this.$emit("onAboutFormSubmit", form);
                    // 表單驗證成功時回傳給父組件
                    this.$emit("onAboutFormValidate", true);
                    return;
                }
                if (
                    this.$isEmpty(this.form.activities) ||
                    this.form.activities.length < 3
                ) {
                    return this.$message({
                        type: "error",
                        message: "最少設定三項可參與活動",
                    });
                }
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                if (this.form.occupationInput === "JOB-OTHERS") {
                    occupation.push({
                        id: "OTHERS",
                        description: this.form.occupationInputOther,
                    });
                }
                // about 表單資料回傳給父組件
                this.$emit("onAboutFormSubmit", form);

                // 表單驗證成功時回傳給父組件
                this.$emit("onAboutFormValidate", true);
            } catch (err) {
                console.log(err);
                // 表單驗證失敗時回傳給父組件
                this.$emit("onAboutFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        setDefault(providerData) {
            console.log("providerData.acceptable_activities => ", providerData);
            // 判斷是否有值
            if (providerData === undefined) {
                return;
            }
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                this.form = {
                    age: providerData.age,
                    weight: providerData.weight,
                    height: providerData.height,
                    service_area: providerData.service_area,
                    description: providerData.description ?? "",
                    taboo: providerData.taboo ?? "",
                    occupationInput: !this.$isEmpty(providerData.occupation)
                        ? providerData.occupation[0].id
                        : "",
                    instagram: !this.$isEmpty(providerData.social)
                        ? providerData.social.instagram
                        : "",
                    welcome_message: !this.$isEmpty(providerData.social)
                        ? providerData.social.welcome_message
                        : "",
                    welcome_message_enabled: !this.$isEmpty(providerData.social)
                        ? providerData.social.welcome_message_enabled
                        : 1,
                    activities:
                        providerData.activities.length > 0
                            ? providerData.activities.map((item) => item.id)
                            : this.chooseActivities.map((item) => item.id),
                    custom_activities: providerData.custom_activities ?? [],
                    allActivities: this.chooseActivities
                        ? sortBy(this.chooseActivities, ["id"])
                        : [],
                    skills:
                        providerData.skills.length > 0
                            ? providerData.skills.map((item) => item.id)
                            : this.chooseSkills.map((item) => item.id),
                    custom_skills: providerData.custom_skills ?? [],
                    allSkills: this.chooseSkills
                        ? sortBy(this.chooseSkills, ["id"])
                        : [],
                };
                // 判斷有將職業設定為其他時材出現
                if (
                    !this.$isEmpty(providerData.occupation) &&
                    providerData.occupation.length >= 2
                ) {
                    this.$set(
                        this.form,
                        "occupationInputOther",
                        providerData.occupation[1].description
                    );
                }
                resolve();
            });
        },
        /**
         * 新增服務商守則文字到輸入匡
         * @param { type String(字串) } data 文字內容
         */
        addRuleBtn(data) {
            // 判斷服務商守則字數大於最大字數限制時不做任何事
            if (this.form.taboo.length >= this.descriptionMaxLengthLimit) {
                return;
            }
            // 目前的服務商守則資料
            let taboo = this.form.taboo;
            // 將點擊按鈕的文字加入服務商守則資料
            taboo += `${data}\n`;
            this.$set(this.form, "taboo", taboo);
        },
        /**
         * 取得所有職業類別
         */
        getOccupationList() {
            this.$api.GetOccupations().then((res) => {
                this.occupationOptions = [...res.data.occupations];
            });
        },
        // 取得服務商預設歡迎訊息樣板
        async getProvierDefaultMessage() {
            if (this.providerData === undefined) {
                return;
            }
            try {
                const data = await firebaseConnectRef(
                    "provider_default_message"
                ).get();
                // 有資料在更新表單內容
                if (data.exists() && this.$isEmpty(this.form.welcome_message)) {
                    this.$set(this.form, "welcome_message", data.val().content);
                }
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得歡迎訊息資料失敗",
                });
            }
        },
        /**
         * 取得可參與活動選項
         */
        async getActivities() {
            try {
                const { data } = await this.$api.GetCanJoinActivitiesApi();
                this.setProviderData({
                    ...this.providerData,
                    activities: data,
                    acceptable_activities: { activities: data },
                });
                return data;
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得可參與活動訊息失敗",
                });
                return [];
            }
        },
    },
    watch: {
        async providerData(val) {
            await this.setDefault(val);
            // 取得服務商歡迎訊息預設樣版
            await this.getProvierDefaultMessage();
        },
    },
    async created() {
        this.getOccupationList();
        // 判斷是新增服務商情況下
        if (this.isAdd) {
            // 取得可參與活動列表
            await this.getActivities();
        }
        // 設定表單資料內容
        await this.setDefault(this.providerData);
        // 取得服務商歡迎訊息預設樣版
        await this.getProvierDefaultMessage();

        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
    async activated() {
        this.getOccupationList();
        // 判斷是新增服務商情況下
        if (this.isAdd) {
            // 取得可參與活動列表
            await this.getActivities();
        }
        // 設定表單資料內容
        await this.setDefault(this.providerData);
        // 取得服務商歡迎訊息預設樣版
        await this.getProvierDefaultMessage();
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
};
</script>
