<template>
    <div class="relative py-5 overflow-x-auto whitespace-nowrap">
        <div v-if="videoList.length > 0" class="inline-block">
            <div
                v-for="(item, index) in videoList"
                :key="index"
                class="inline-block"
            >
                <el-checkbox-group
                    v-model="hiddenByCategory5Videos"
                    class="inline-block"
                >
                    <div class="inline-block align-middle">
                        <div class="relative mx-2">
                            <img
                                class="w-32 h-32 rounded-lg"
                                :src="item.cover"
                                alt=""
                            />
                            <div
                                class="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black rounded-lg cursor-pointer bg-opacity-30"
                                @click="openDialog(item.url)"
                            >
                                <i
                                    class="text-5xl text-white opacity-50 icofont-play-alt-2"
                                ></i>
                            </div>
                            <div
                                class="absolute flex items-center justify-center w-6 h-6 text-xs text-white bg-black bg-opacity-50 rounded-full cursor-pointer -top-2 -right-2 md:text-sm"
                                @click="deleteVideo(index)"
                            >
                                <i class="icofont-ui-close text-[10px]"></i>
                            </div>
                        </div>
                        <el-checkbox
                            class="mx-2"
                            :label="item.id"
                        ></el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
        <!-- 影片上傳組件 -->
        <VideoUpload
            ref="videoUpload"
            class="block align-middle"
            :videoLink="videoLink"
            :progress="progress"
            @onCoverCropped="coverCroppedData"
            @videoUpload="uploadVideo"
        />
        <!-- 播放影片彈窗 -->
        <el-dialog
            v-if="showDialog"
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            :show-close="false"
            @close="closeDialog"
        >
            <div class="relative">
                <span
                    class="absolute cursor-pointer right-5 -top-10"
                    @click="closeDialog"
                    ><i class="icofont-ui-close"></i
                ></span>
            </div>
            <video
                class="w-full"
                playsinline
                controls
                autoplay
                :src="playVideo"
            ></video>
        </el-dialog>
        <!-- <button @click.prevent="deleteAllVideo"
                class="blue-btn">刪除全部影片</button> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
// 多影片上傳組件
import VideoUpload from "@/components/VideoUpload.vue";
export default {
    components: {
        VideoUpload,
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            // 裁切後影片封面照
            videoList: [],
            // 封面照裁切後檔案
            coverPhotoFile: null,
            // 影片連結
            videoLink: null,
            // 上傳進度條
            progress: 0,
            // 影片 id
            videoId: null,
            // 以上傳可播放影片連結
            playVideo: null,
            // 打開彈窗
            showDialog: false,
            // 選擇要設定為第五分類時要隱藏的影片
            hiddenByCategory5Videos: [],
        };
    },
    methods: {
        /**
         * 裁切成功後回傳財切檔案
         * @param { type Base64 (檔案) } image 裁切圖片檔
         * @param { type Blob or File(Blob 或 檔案格式) } file 圖片檔案
         */
        async coverCroppedData(val, file) {
            try {
                const result = new FormData();
                result.append("image", file);
                const { data } = await this.$api.UploadVideoCoverApi(
                    this.$route.params.id,
                    this.videoId,
                    result
                );
                this.videoList.push({
                    cover: data.cover.url,
                    id: this.videoId,
                    url: this.videoLink,
                });
                this.videoLink = null;
                this.videoId = null;
                this.$message({
                    type: "success",
                    message: "上傳影片封面照成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "上傳影片封面照失敗",
                });
            }
            this.coverPhotoFile = file;
        },
        /**
         * 後端上傳影片 api
         * @param { type File (檔案) } video 影片檔案
         */
        async uploadVideo(video) {
            try {
                // 上傳進度條方法
                const onUploadProgress = (progressEvent) => {
                    this.progress = Number(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                };
                const { data } = await this.$api.UploadVideoApi(
                    this.$route.params.id,
                    video,
                    onUploadProgress
                );
                this.videoId = data.video.id;
                this.videoLink = data.video.url;
                this.$message({
                    type: "success",
                    message: "上傳影片成功",
                });
            } catch (err) {
                this.videoId = null;
                this.videoLink = null;
                console.log(err);
                this.$message({
                    type: "error",
                    message: "上傳影片失敗",
                });
            }
        },
        //移除圖片
        async deleteVideo(index) {
            try {
                await this.$confirm("您確定刪除此影片嗎?", "提示", {
                    confirmButtonText: "確定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass:
                        "top-1/2 absolute z-20 md:w-[500px] w-full md:left-1/2 left-0",
                });
                try {
                    await this.$api.DeleteVideoApi(
                        this.$route.params.id,
                        this.videoList[index].id
                    );
                    this.videoList.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "刪除成功",
                    });
                } catch (err) {
                    this.$message({
                        type: "error",
                        message: "刪除失敗",
                    });
                }
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 開啟彈窗
         * @param { type String(字串) } videoLink 播放影片連結
         */
        openDialog(videoLink) {
            this.playVideo = videoLink;
            this.showDialog = true;
        },
        // 關閉彈窗
        closeDialog() {
            // 關閉彈窗
            this.showDialog = false;
        },
        // 設定表單值
        setDefault() {
            if (this.$isEmpty(this.providerData)) {
                return;
            }
            if (!this.$isEmpty(this.providerData.videos)) {
                this.videoList = this.providerData.videos;
            }
            // 判斷是否有過濾隱藏於第五分類影片
            if (!this.$isEmpty(this.providerData.videos)) {
                this.hiddenByCategory5Videos = this.providerData.videos
                    .filter((item) => item.is_erotic === 1)
                    .map((item) => item.id);
            }
        },
        // // 刪除全部影片功能
        // deleteAllVideo() {
        //     if (this.videoList.length > 0) {
        //         this.videoList.forEach(async (item) => {
        //             try {
        //                 await DeleteVideoApi(this.$route.params.id, item.id);
        //                 this.$message({
        //                     type: "success",
        //                     message: `${item.id}刪除成功`
        //                 });
        //             } catch (err) {
        //                 this.$message({
        //                     type: "error",
        //                     message: `${item.id}刪除失敗`
        //                 });
        //             }
        //         });
        //     }
        // }
        onSubmit() {
            // 判斷有選擇隱藏第五分類照片時觸發
            if (!this.$isEmpty(this.hiddenByCategory5Videos)) {
                this.$emit(
                    "onHiddenByCategory5Videos",
                    this.hiddenByCategory5Videos
                );
            }
        },
    },

    watch: {
        providerData(val) {
            this.videoList = [];
            this.setDefault();
        },
    },

    created() {
        this.setDefault();
    },
};
</script>
