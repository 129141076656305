<template>
    <div class="mt-10">
        <h5 class="mt-10 mb-5 text-lg md:text-2xl md:mb-10">撥款帳戶</h5>
        <el-form ref="form" :model="form" :rules="rules">
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="bankCode"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行代碼
                        <el-input
                            v-model="form.bankCode"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="swift"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        通匯代碼
                        <el-input
                            v-model="form.swift"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
            </div>
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="accountName"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行戶名
                        <el-input
                            v-model="form.accountName"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
                <el-form-item
                    class="w-full col-span-2 mb-5 md:col-span-1"
                    prop="accountId"
                >
                    <label class="mb-0 leading-none text-gray-400">
                        銀行帳號
                        <el-input
                            v-model="form.accountId"
                            class="w-full"
                        ></el-input>
                    </label>
                </el-form-item>
            </div>
        </el-form>
        <BankPhotoUpload
            ref="bankPhotoUpload"
            class="mt-10"
            @onUploadBankPhotoSuccess="uploadBankPhotoSuccess"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
// 存褶影本上傳組件
import BankPhotoUpload from "@/components/BankAccountUpload.vue";
export default {
    components: {
        BankPhotoUpload,
    },
    props: {
        // 判斷是否為新增
        isAdd: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            form: {
                bankCode: 822,
            },
            rules: {
                bankCode: [
                    {
                        required: true,
                        message: "銀行代碼為必填",
                        trigger: "blur",
                    },
                ],
                swift: [
                    {
                        required: true,
                        message: "通匯代碼為必填",
                        trigger: "blur",
                    },
                ],
                accountName: [
                    {
                        required: true,
                        message: "帳戶名稱為必填",
                        trigger: "blur",
                    },
                ],
                accountId: [
                    {
                        required: true,
                        message: "銀行帳號為必填",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            if (this.isAdd) {
                // salary 表單資料回傳給父組件
                this.$emit("update:salaryFormData", this.form);
                // salary  表單驗證成功時回傳給父組件
                this.$emit("update:salaryFormValidate", true);
                return;
            }
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.$refs.bankPhotoUpload.validate();
                // salary 表單資料回傳給父組件
                this.$emit("update:salaryFormData", this.form);
                // salary  表單驗證成功時回傳給父組件
                this.$emit("update:salaryFormValidate", true);
            } catch (err) {
                // salary 表單驗證失敗時回傳給父組件
                this.$emit("update:salaryFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 設定表單值
        setDefault(val) {
            // 判斷是否有值
            if (val === undefined) {
                return;
            }
            if (!this.$isEmpty(val.banking)) {
                this.form = {
                    bankCode: val.banking.bankCode,
                    swift: val.banking.swift,
                    accountName: val.banking.accountName,
                    accountId: val.banking.accountId,
                };
            }
            // 取得 銀行存摺照片
            this.$refs.bankPhotoUpload.getOrderPassbookPhoto();
        },
        // 用來確認上傳 存摺照片有上傳成功
        uploadBankPhotoSuccess(val) {
            this.$emit("update:onUploadBankPhotoSuccess", val);
        },
    },
    watch: {
        providerData(val) {
            this.setDefault(val);
        },
    },
    created() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
    activated() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
};
</script>
