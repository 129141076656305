<template>
    <div class="relative grid grid-cols-12 gap-4 px-5">
        <div class="col-span-3 px-2">
            <AvatarUpload
                ref="avatarUpload"
                v-loading="loading"
                :showTip="false"
                :defaultImg="defaultAvatar"
                :useDialog="true"
                @onCropped="avatarCroppedData"
            />
            <el-form ref="form" :model="form" :rules="rules">
                <div v-if="isUpdate && form.broker">
                    <el-form-item class="w-full mt-3">
                        <div>
                            <label class="block mb-0 text-gray-400"
                                >介紹人</label
                            >
                            <p class="-mt-2 text-base">
                                {{ form.broker.name }}
                            </p>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item v-if="isUpdate" class="w-full" prop="status">
                    <label class="block mb-0 text-gray-400">帳號狀態</label>
                    <el-select
                        v-model="form.status"
                        class="w-full"
                        :disabled="
                            providerData.status === -2 || form.role === 1
                        "
                        placeholder="請選擇狀態"
                    >
                        <el-option
                            v-for="(option, index) in statusOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                    <div v-if="form.role === 1" class="text-red-600">
                        如需選擇帳號狀態 請先修改上架狀態
                    </div>
                </el-form-item>
                <el-form-item v-if="isUpdate" class="w-full" prop="role">
                    <label class="block mb-0 text-gray-400">上架狀態</label>
                    <el-select
                        v-model="form.role"
                        class="w-full"
                        placeholder="請選擇狀態"
                        @change="
                            form.role !== 1
                                ? $set(form, 'status', form.status)
                                : $set(form, 'status', 0)
                        "
                    >
                        <el-option
                            v-for="(option, index) in alreadyOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="role">
                    <label class="block mb-0 text-gray-400">設定標籤</label>
                    <el-select
                        v-model="form.badges"
                        class="w-full"
                        multiple
                        placeholder="請選擇狀態"
                    >
                        <el-option
                            v-for="(option, index) in [
                                { label: '快閃皇后', value: 1 },
                            ]"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="withdraw">
                    <label class="block mb-0 text-gray-400">立即提領</label>
                    <el-select
                        v-model="form.withdraw"
                        class="w-full"
                        placeholder="請選擇狀態"
                    >
                        <el-option
                            v-for="(option, index) in withdrawOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="stealth">
                    <label class="block mb-0 text-gray-400">隱身術</label>
                    <el-select
                        v-model="form.stealth"
                        class="w-full"
                        placeholder="請選擇"
                        @change="
                            form.stealth !== 1
                                ? $set(form, 'stealth', form.stealth)
                                : $set(stealth, 'status', 0)
                        "
                    >
                        <el-option
                            v-for="(option, index) in stealthOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="enablePayByCash">
                    <label class="block mb-0 text-gray-400">現金收款</label>
                    <el-select
                        v-model="form.enablePayByCash"
                        class="w-full"
                        placeholder="請選擇"
                    >
                        <el-option
                            v-for="(option, index) in enablePayByCashOptions"
                            :key="index"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="
                        salesmanStatus !== null &&
                        ![0, -1].includes(salesmanStatus)
                    "
                    class="w-full"
                >
                    <div>城市推廣人</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-select
                            v-model="salesmanStatus"
                            class="flex-1"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in salesmanStatusOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </label>
                    <div class="text-gray-400">城市推廣人邀請碼</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-input
                            v-model="salesmanCode"
                            class="flex-1"
                            show-word-limit
                            placeholder="請輸入代碼"
                            minlength="2"
                            maxlength="10"
                        >
                        </el-input>
                    </label>
                </el-form-item>
            </el-form>
            <div
                v-if="providerData.promoter !== null"
                class="text-sm text-gray-400"
            >
                <div class="mb-2">連結的城市推廣人</div>
                <div class="flex items-center">
                    <div class="flex-grow-0">
                        <Avatar
                            :size="['w-10', 'h-10']"
                            :backgroundImg="
                                providerData.promoter.user.thumbnails.avatar[
                                    '360x360'
                                ]
                            "
                        />
                    </div>
                    <a
                        :href="`/#/user_list/${
                            salesmanIsProvider
                                ? 'provider_update'
                                : 'member_update'
                        }/${providerData.promoter.user.id}`"
                        target="salesman"
                        class="ml-5 text-red-500 underline"
                    >
                        {{ providerData.promoter.user.name }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-span-9 px-2">
            <el-form ref="form" :model="form" :rules="rules">
                <div v-if="!isUpdate">
                    <div class="title">經紀人合約</div>
                    <div class="grid grid-cols-3 mb-5 md:gap-4">
                        <el-form-item
                            class="w-full col-span-2 mb-3 md:col-span-1"
                            prop="broker_id"
                        >
                            <label class="mb-0 leading-none text-gray-400">
                                介紹人
                                <el-select
                                    v-model="form.broker_id"
                                    class="w-full"
                                    filterable
                                    clearable
                                >
                                    <el-option
                                        v-for="item in allBrokerOption"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </label>
                        </el-form-item>
                    </div>
                </div>
                <div class="title">個人資料</div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="phone"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            手機號碼
                            <el-input
                                v-model="form.phone"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="password"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            密碼
                            <div class="relative">
                                <el-input
                                    v-model="form.password"
                                    class="w-full"
                                    :type="showPassword ? 'text' : 'password'"
                                ></el-input>
                                <!-- 眼睛開關 用來顯示密碼使用 -->
                                <span
                                    class="absolute text-xl cursor-pointer right-2 top-1"
                                    @click="showPassword = !showPassword"
                                >
                                    <i
                                        v-if="showPassword"
                                        class="icofont-eye-alt"
                                    ></i>
                                    <i
                                        v-if="!showPassword"
                                        class="icofont-eye-blocked"
                                    ></i>
                                </span>
                            </div>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="name"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            暱稱
                            <el-input
                                v-model="form.name"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="real_name"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            真實姓名
                            <el-input
                                v-model="form.real_name"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="gender"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            性別
                            <el-select
                                v-model="form.gender"
                                class="w-full"
                                placeholder="請選擇性別"
                            >
                                <el-option
                                    v-for="(option, index) in genderOptions"
                                    :key="index"
                                    :label="option.label"
                                    :value="option.value"
                                >
                                </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="birthday"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            生日
                            <el-date-picker
                                v-model="form.birthday"
                                class="w-full"
                                type="date"
                                :default-value="defaultValue()"
                                :picker-options="pickerOptions"
                                placeholder="請選擇日期"
                            >
                            </el-date-picker>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full col-span-2 mb-3 md:col-span-1">
                        <el-form-item prop="email">
                            <label class="mb-0 leading-none text-gray-400">
                                Email
                                <el-input
                                    v-model="form.email"
                                    placeholder="請輸入電子郵件"
                                    class="w-full"
                                />
                            </label>
                        </el-form-item>
                        <el-checkbox-group
                            v-model="form.marketing_notification"
                        >
                            <el-checkbox
                                class="leading-none text-gray-300"
                                :label="0"
                                >我不想收到&nbsp;City&nbsp;Banana&nbsp;相關的行銷資訊</el-checkbox
                            >
                        </el-checkbox-group>
                    </div>
                    <el-form-item prop="government_cert">
                        <label class="block mb-0 text-gray-400">身份證</label>
                        <div class="flex">
                            <el-select
                                v-model="form.government_cert"
                                class="rounded-tr-none rounded-none max-w-[120px]"
                                placeholder="證件型態"
                            >
                                <el-option
                                    v-for="(item, index) in certOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="government_id">
                                <el-input
                                    v-model="form.government_id"
                                    placeholder="請輸入身份證"
                                    class="w-full"
                                />
                            </el-form-item>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex items-end mt-2 mb-3">
                    <el-form-item prop="district">
                        <label class="leading-none text-gray-400">
                            <span class="block mb-3">所在地區</span>
                            <el-select
                                v-model="form.district"
                                class="max-w-[150px] rounded-tr-none rounded-none"
                                placeholder="請選擇地區"
                            >
                                <el-option
                                    v-for="(item, key) in areaOptions"
                                    :key="key"
                                    :label="item.name"
                                    :value="key"
                                >
                                </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item class="flex-1" prop="address">
                        <el-input v-model="form.address"> </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                @submit.native.prevent
            >
                <span class="block mb-3 text-sm leading-none text-gray-400"
                    >客服註記</span
                >
                <slot name="provider-remarks"></slot>
                <el-form-item v-permission="['update']" class="w-full">
                    <div v-loading="sendNoteloading" class="flex items-end">
                        <label class="flex-1">
                            <el-input
                                v-model="note"
                                class="w-full"
                                placeholder="請輸入註記內容"
                            >
                            </el-input>
                        </label>
                        <btn
                            color="border-red-500 border text-red-500"
                            size="md:text-sm text-xs px-2 py-2 rounded max-w-[150px]"
                            customClass="ml-2"
                            @onClick="sendNote(note)"
                        >
                            送出註記
                        </btn>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// 頭像組件
import Avatar from "@/components/Avatar.vue";
// 上傳頭像組件
import AvatarUpload from "../../../../../components/AvatarUpload.vue";
// 使用語系檔取得地區資料
import { areas } from "../../../../../langs/tw.json";
/**
 * formNickNameCheck: 驗證暱稱是否有超過 10個字 以及 不可輸入 emoji 符號
 * formCheckUserAge: 判斷是否18歲
 * formEmailCheckAlready: 判斷此信箱是否有人使用
 */
import {
    formCheckUserAge,
    formNickNameCheck,
    formEmailCheckAlready,
} from "../../../../../service/formRules";
// 判斷是否小於 18 歲日期 disabled 跟 未來時間 disabled 以及預設日期為 18歲當年月份
import datepickerMixin from "@/service/datepickerMixin";
// 導入 firebase 更新聊天室全部聊天對象資料方法
import firebaseChatMixin from "@/service/firebaseChatMixin";
// 導入 實名驗證對應狀態名詞
import AuthenticationCheck from "@/service/authenticationCheckMixin";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
import { isEmpty } from "@/service/anyService";

export default {
    mixins: [datepickerMixin, firebaseChatMixin, AuthenticationCheck],
    components: {
        Avatar,
        AvatarUpload,
        Btn,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為更新
        isUpdate: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為 provider
        isProvider: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
        salesmanIsProvider() {
            return false;
        },
    },
    data() {
        return {
            form: {
                marketing_notification: [],
                setting: {},
                badges: [],
            },
            rules: {
                broker_id: [
                    {
                        required: false,
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "手機為必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /8869[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                real_name: [
                    {
                        required: true,
                        message: "真實姓名為必填",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        message: "真實姓名需兩字以上",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "暱稱為必填",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: this.formNickNameCheck,
                        trigger: "blur",
                    },
                ],
                birthday: [
                    {
                        validator: this.formCheckUserAge,
                        trigger: "change",
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: "性別為必填",
                        trigger: ["change", "blur"],
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "地址為必填",
                        trigger: "blur",
                    },
                ],
                district: [
                    {
                        required: true,
                        message: "所在區域為必填",
                        trigger: ["blur"],
                    },
                ],
                email: [
                    {
                        required: true,
                        validator: this.formEmailCheckExampleDomain,
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: this.formEmailCheckAlready,
                        trigger: "blur",
                    },
                ],
                government_id: [
                    {
                        required: true,
                        message: "身份證字號為必填",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                government_cert: [
                    {
                        required: true,
                        message: "證件型態為必填",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                role: [
                    {
                        required: true,
                        message: "請修改上架狀態",
                        trigger: "change",
                    },
                    // { required: true, pattern: /^[1-2]{1}$/, message: "請修改上架狀態", trigger: "blur" }
                ],
            },
            // 區域選擇,
            areaOptions: areas,
            // 選擇性別資料
            genderOptions: [
                {
                    label: "男",
                    value: "male",
                },
                {
                    label: "女",
                    value: "female",
                },
            ],
            // 經紀人公司選項
            allBrokerOption: [],
            // 經紀合約方案選項
            providerPlans: [],
            // 身份認證類別選擇
            certOptions: [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
            ],
            // 上架狀態選擇
            alreadyOptions: [
                { label: "服務商上架", value: 1 },
                { label: "服務商下架", value: 2 },
                // { label: "請選擇上架狀態", value: 0 }
            ],
            // 是否隱藏服務商在列表上
            stealthOptions: [
                { label: "關閉", value: 0 },
                { label: "開啟", value: 1 },
                // { label: "請選擇上架狀態", value: 0 }
            ],
            // 立即提領狀態選擇
            withdrawOptions: [
                // false 狀態才可提領 因為資料庫欄位命名 所以 false 才是為可提領
                { label: "可以提領", value: false },
                { label: "不能提領", value: true },
                // { label: "請選擇上架狀態", value: 0 }
            ],
            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: 0 },
                // { label: "使用中", value: 2 },
                { label: "停權", value: -1 },
                { label: "永久停權", value: -2 },
            ],
            // 判斷是否開啟即刻快閃現金單功能
            enablePayByCashOptions: [
                { label: "開啟", value: 1 },
                { label: "關閉", value: 0 },
            ],
            // 城市推廣人狀態
            salesmanStatusOptions: [
                { label: "使用中", value: 1 },
                // { label: "未通過", value: -1 },
                { label: "停權", value: -2 },
            ],
            // 判斷 input 輸入框使用 text 或者 password模式
            showPassword: false,
            // 判斷是否啟用旋轉動畫
            loading: false,
            // 預設大頭照
            defaultAvatar: null,
            // 客服註記
            note: null,
            // 發送客服註記旋轉動畫
            sendNoteloading: false,
            // 判斷是否顯示 永久停權的警示彈窗
            showDialog: false,
            // 過濾今天以前的日期
            pickerOptionsExp: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            // 城市推廣人狀態
            salesmanStatus: null,
            // 城市推廣人代碼
            salesmanCode: null,
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        ...mapActions("apiStore", ["errorCallback"]),
        // 判斷email 是否在特定網域
        formEmailCheckExampleDomain(rule, value, callback) {
            if (isEmpty(value)) {
                return callback();
            }
            // 需過濾的網址
            const filterDomains = ["example"];
            // 取得輸入匡eamil值 並且取出 @ 之後的網址 接這在取出 . 之前的字串
            const emailDomain = value.split("@")[1].split(".")[0];
            if (filterDomains.includes(emailDomain)) {
                return callback(new Error("email 格式錯誤"));
            }
            return callback();
        },
        // 判斷信箱是否重複
        async formEmailCheckAlready(rule, value, callback) {
            const { success, message } = await formEmailCheckAlready(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        // 暱稱驗證
        async formNickNameCheck(rule, value, callback) {
            const { success, message } = await formNickNameCheck(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        // 判斷是否18歲
        async formCheckUserAge(rule, value, callback) {
            const { success, message } = await formCheckUserAge(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        /**
         * 裁切成功後回傳財切檔案
         * @param { type Blob(檔案) } val 裁切圖片檔
         */
        async avatarCroppedData(val) {
            this.loading = true;
            try {
                const result = new FormData();
                result.append("image", val);
                const { data } = await this.$api.UploadAvatarApi(
                    this.$route.params.id,
                    result
                );
                // // 重新取得使用者資料
                const userData = await this.getData(true);
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                await this.updateFirebaseChatRoomUserData(
                    userData.banana_id,
                    userData
                );
                // 設定上傳後的圖片網址到 頭像組件中
                this.$refs.avatarUpload.setCropped(data.avatar);
                this.defaultAvatar =
                    data.thumbnails === undefined
                        ? data.avatar
                        : data.thumbnails.avatar["360x360"];
                this.loading = false;
                this.$message({
                    type: "success",
                    message: "上傳頭像成功",
                });
                this.$emit("updateAvatar", data.avatar);
            } catch (err) {
                console.log(err);
                this.loading = false;
                // 上傳失敗時要清除上傳頭像組件資料
                this.$refs.avatarUpload.handleRemove();
                this.$message({
                    type: "error",
                    message: "上傳頭像失敗",
                });
            }
        },
        /**
         * 取得當前服務商資料
         * @param { type Boolean(布林值) } isUploadAvatar 判斷是上傳頭像時 不更新服務商store資料
         */
        async getData(isUploadAvatar = false) {
            try {
                // 取得服務商資料
                const { data } = await this.$api.GetUserDataApi(
                    this.$route.params.id
                );
                this.loading = false;
                // 不是上傳頭像時設定服務商資料 (因為 watch機制影響 上傳頭像會清空服務商詳細資料表單中值)
                if (!isUploadAvatar) {
                    // 設定 服務商 資料
                    this.setProviderData(data);
                }
                return data;
            } catch (err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        },
        // 設定表單預設值
        setDefault(providerData) {
            if (providerData === undefined) {
                return;
            }
            // 設定預設大頭照
            this.defaultAvatar =
                providerData.thumbnails === undefined
                    ? providerData.avatar
                    : providerData.thumbnails.avatar["360x360"];
            this.form = {
                status: providerData.status === 2 ? 0 : providerData.status,
                phone: providerData.phone,
                broker_id: providerData.broker_id,
                broker: providerData.broker,
                name: providerData.name,
                real_name: providerData.real_name,
                gender: providerData.gender,
                birthday: providerData.birthday,
                email: providerData.email,
                government_id: providerData.government_id,
                government_cert: providerData.government_cert ?? null,
                district: providerData.district,
                address: providerData.address,
                setting: providerData.setting,
                badges: this.$isEmpty(providerData.badges)
                    ? []
                    : providerData.badges.map((item) => item.id),
                // false 狀態才可提領 因為資料庫欄位命名 所以 false 才是為可提領
                withdraw: !this.$isEmpty(providerData.setting)
                    ? providerData.setting.disableWithdraw
                    : false,
                // 判斷是否開啟現金收款
                enablePayByCash: !this.$isEmpty(providerData.setting)
                    ? providerData.setting.enablePayByCash
                    : 0,
                // 判斷是否要將服務商顯示在列表頁
                stealth: providerData.stealth ?? 0,
            };
            if (this.isUpdate) {
                this.$set(this.form, "role", providerData.role);
            }
            if (this.form.setting && this.form.setting.plan) {
                this.plan = { ...this.form.setting.plan };
            } else {
                this.plan = {};
            }
            this.authLevel = providerData.status;
            this.$set(this.form, "marketing_notification", [
                providerData.marketing_notification,
            ]);
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                if (!this.isUpdate) {
                    /**
                     * 判斷目前是否有勾選不想要收到網頁廣告信 因為有勾選值為 false 特列 因此需要特別處理
                     * 原本值為陣列 取消勾選後 裡面值為空陣列 因此判斷空陣列傳送值 為 true
                     * 有值時傳送值為 false
                     */
                    if (this.form.marketing_notification.length <= 0) {
                        this.form.marketing_notification = 1;
                    } else {
                        this.form.marketing_notification = 0;
                    }
                    // profile 表單資料回傳給父組件
                    this.$emit("update:profileFormData", {
                        ...this.form,
                        setting: {
                            ...this.form.setting,
                            plan: this.form.broker_id ? this.plan : null,
                        },
                    });
                    // profile 表單驗證成功時回傳給父組件
                    this.$emit("update:profileFormValidate", true);

                    return;
                }
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // 判斷沒有有上傳大頭照
                if (this.defaultAvatar === "/img/avatar/avatar-icon.svg") {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return this.$message({
                        type: "error",
                        message: "請上傳大頭照",
                    });
                }
                // 判斷供給者身份 因為預設為 0 是會員 需要做判斷 傳送值如果為 0 時 不可新增
                if (
                    this.isUpdate &&
                    this.isProvider &&
                    this.$isEmpty(this.providerData.role)
                ) {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return this.$message({
                        type: "error",
                        message: "請選擇上架狀態",
                    });
                }
                /**
                 * 判斷是否有輸入非英文數字以外的文字
                 */
                const regex = /[A-Za-z0-9]/gm;
                if (
                    !regex.test(this.salesmanCode) &&
                    !isEmpty(this.salesmanCode)
                ) {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return this.$message({
                        type: "error",
                        message: "邀請碼格式錯誤",
                    });
                }
                // 當選擇永久停權狀態時要執行
                if (
                    this.form.status === -2 &&
                    this.providerData.status !== -2
                ) {
                    // 判斷是否確認更改使用者狀態為永久停權
                    const isSuspension = await this.isSuspension();
                    // 當選擇取消永久停權時 不在往下執行更新
                    if (!isSuspension) {
                        // profile 表單驗證失敗時回傳給父組件
                        this.$emit("update:profileFormValidate", false);
                        return;
                    }
                }
                /**
                 * 判斷目前是否有勾選不想要收到網頁廣告信 因為有勾選值為 false 特列 因此需要特別處理
                 * 原本值為陣列 取消勾選後 裡面值為空陣列 因此判斷空陣列傳送值 為 true
                 * 有值時傳送值為 false
                 */
                if (this.form.marketing_notification.length <= 0) {
                    this.form.marketing_notification = 1;
                } else {
                    this.form.marketing_notification = 0;
                }
                // 判斷服務商是否可立即提領設定值 與 是否可接受現金單
                this.form.setting = {
                    ...this.form.setting,
                    disableWithdraw: this.form.withdraw,
                    enablePayByCash: this.form.enablePayByCash,
                };
                if (
                    !isEmpty(this.form.birthday) ||
                    this.form.birthday instanceof Date
                ) {
                    // 轉換生日日期格式
                    this.form.birthday = this.$moment(
                        this.form.birthday
                    ).format("YYYY-MM-DD");
                }
                // profile 表單資料回傳給父組件
                this.$emit("update:profileFormData", {
                    ...this.form,
                    setting: {
                        ...this.form.setting,
                        plan: this.form.broker_id ? this.plan : null,
                    },
                });
                // profile 表單驗證成功時回傳給父組件
                this.$emit("update:profileFormValidate", true);
            } catch (err) {
                console.log("profile err =>", err);
                // profile 表單驗證失敗時回傳給父組件
                this.$emit("update:profileFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 發送客服註記
         * @param { type String (字串) } val 註記內容
         */
        async sendNote(val) {
            this.sendNoteloading = true;
            try {
                await this.$api.CreateNotesApi(this.$route.params.id, val);
                this.sendNoteloading = false;
                this.note = null;
                this.$message({
                    type: "success",
                    message: "新增客服註記成功",
                });
                // 新增完後 回傳給父組件 取得最新列表
                this.$emit("updateNotesList", true);
            } catch (err) {
                this.sendNoteloading = false;
                this.$message({
                    type: "error",
                    message: "新增客服註記失敗",
                });
            }
        },
        // 判斷選擇永久停權時
        async isSuspension() {
            try {
                const opts = {
                    title: "確認是否永久停權此用戶",
                    message: "要執行此操作，請在下面的表格中輸入 CONFIRM。",
                };
                await this.$pop.popConfirmType(opts);
                // // 重新取得使用者資料
                const userData = await this.getData(true);
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                await this.updateFirebaseChatRoomUserData(
                    userData.banana_id,
                    userData
                );

                return true;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取消此操作",
                });
                return false;
            }
        },
        // 導到實名驗證頁
        openVerification() {
            window.open(
                `/#/user_list/verification_detail/${this.$route.params.id}`
            );
        },
        // 經紀人相關
        async loadAllBroker() {
            const { status, data } = await this.$api
                .GetBrokersListApi({
                    name: "",
                    limit: 10000,
                    page: 1,
                })
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.allBrokerOption = [
                    ...data.data.map((i) => {
                        return {
                            label: i.name,
                            value: i.id,
                        };
                    }),
                ];
            }
        },
        // 經紀人相關
        async getPrviderPlan() {
            const { status, data } = await this.$api
                .GetPrviderPlan()
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.providerPlans = [
                    ...data.provider.map((i) => {
                        return {
                            label: `${i.id} ${i.name}`,
                            value: i.id,
                        };
                    }),
                ];
            }
        },
        // 取得城市推廣人狀態
        async getSalesmanStatus() {
            this.loading = true;
            try {
                const {
                    data: { status, invitation_code },
                } = await this.$api.GetApplyCityPlanSalesmanApi(
                    this.$route.params.id
                );
                this.salesmanStatus = status;
                this.salesmanCode = invitation_code;
            } catch (err) {
            } finally {
                this.loading = false;
            }
        },
        // 更新城市推廣人狀態
        async updateSalesmanStatus() {
            // 沒有城市推廣人資料時不往下執行
            if (this.salesmanStatus === null) {
                return;
            }
            try {
                await this.$api.UpdateCityPlanSalesmanStatusApi({
                    userId: this.$route.params.id,
                    status: this.salesmanStatus,
                    invitation_code: this.salesmanCode,
                });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
    },
    watch: {
        providerData(val) {
            this.setDefault(val);
            // 取得城市推廣人資料
            this.getSalesmanStatus();
        },
    },
    created() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
        // 設定表單資料內容
        this.setDefault(this.providerData);
        if (this.isUpdate) {
            this.alreadyOptions = [
                { label: "回到會員身份", value: 0 },
                { label: "服務商上架", value: 1 },
                { label: "服務商下架", value: 2 },
            ];
        } else {
            // 合約需要選項
            this.loadAllBroker();
            this.getPrviderPlan();
        }
    },
    activated() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
        // 設定表單資料內容
        this.setDefault(this.providerData);
        // 合約需要選項
        this.loadAllBroker();
        this.getPrviderPlan();
    },
};
</script>

<style lang="scss" scoped>
.title {
    width: auto;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
}
::v-deep .el-date-editor {
    @apply w-full;
}
</style>
