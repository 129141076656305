<template>
    <div>
        <div class="inline-block">
            <div
                v-for="activity in allActivities"
                :key="activity.name"
                :class="selectedActivities.indexOf(activity.id) !== -1 ? activeClass : defaultClass"
                class="py-1.5 mx-1 mt-2 rounded px-4 cursor-pointer inline-block text-xs"
                @click.prevent="onSelectActivity(activity.id)">
                {{ activity.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    props: {
        // 選中時顏色
        activeClass: {
            type: String,
            default: null,
        },
        // 預設時顏色
        defaultClass: {
            type: String,
            default: null,
        },
        // 以選取得活動項目
        selectedActivities: {
            type: Array,
            default() {
                return [];
            },
        },
        // 可參與活動總表
        allActivities: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            // 可參與活動
            activities: [],
            // 選擇的活動
            selectActivities: [],
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 選擇活動事件
         * @param { type Object(物件) } val 選中的活動
         */
        onSelectActivity(val) {
            // 判斷選中活動值 是否存在 目前選中陣列中
            if (this.selectedActivities.indexOf(parseInt(val)) !== -1) {
                // 取得目前選中活動 比對目前活動值陣列中 index 位子
                const arrIndex = this.selectedActivities.findIndex((item) => item == val);
                // 刪除目前 index 位子值 作為取消選取
                this.selectedActivities.splice(arrIndex, 1);
            } else {
                // 將選中值 塞進活動陣列中
                this.selectedActivities.push(parseInt(val));
            }
            this.$emit("onSelectActivities", this.selectedActivities);
        },
    },
};
</script>
