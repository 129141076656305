<template>
    <div class="mt-10">
        <h5 class="mt-10 mb-5 text-lg md:text-2xl md:mb-10">身份證照片</h5>
        <div v-if="idCard.frontPhoto" class="flex text-gray-400">
            <div class="max-w-[500px] mx-auto w-full mr-5">
                <p class="mb-5">身份證正面</p>
                <img class="rounded-lg" :src="idCard.frontPhoto" />
            </div>
            <div class="max-w-[500px] mx-auto w-full">
                <p class="mb-5">身份證背面</p>
                <img class="rounded-lg" :src="idCard.backPhoto" />
            </div>
        </div>
        <div v-else class="p-2 pt-20">
            <div class="relative flex items-center justify-center top-10">
                <font-awesome-icon
                    class="absolute z-10 text-red-500"
                    style="font-size: 150px"
                    :icon="['fas', 'times']"
                />
                <font-awesome-icon
                    class="absolute"
                    style="font-size: 150px"
                    :icon="['far', 'id-card']"
                />
                <p class="absolute mb-5" style="top: 100px">未提供身份證照片</p>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
const __sfc_main = {};
__sfc_main.props = {
  idCard: {
    type: Object,
    default() {
      return {
        frontPhoto: "",
        backPhoto: ""
      };
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>
